<template>
  <div class="flex flex-col justify-between" style="min-height: calc(100dvh - 188px);">
    <div>
      <p class="text-black font-medium md:text-2xl text-xl md:mb-8 mb-5">{{ $t('New Module') }}</p>
      <div>
        <p class="font-medium md:text-lg text-black">{{ 'Module Name' }}</p>
        <p v-if="editable === false" class="md:mt-4 mt-2 text-grey-dark">{{ module.name }}</p>
        <div v-else class="md:mt-4 mt-2">
          <input v-model="module.name" autocomplete="nofill" class="md:w-1/2 w-full input-order" v-validate="'required'" name="module name" />
          <p class="text-danger text-sm" v-show="errors.has('module name')">{{ errors.first('module name') }}</p>
        </div>
      </div>

      <div class="mt-4">
        <p class="font-medium md:text-lg text-black">{{ 'Module Material' }}</p>
        <a v-if="editable === false" class="md:mt-4 mt-2 " :href="module.files[0].file_key" target="blank">{{ $t('Video Link') }}</a>
        <div v-else class="md:mt-4 mt-2">
          <input v-model="module.module_url" autocomplete="nofill" class="md:w-1/2 w-full input-order" v-validate="'required'" name="module name" />
          <p class="text-danger text-sm" v-show="errors.has('module name')">{{ errors.first('module name') }}</p>
        </div>
      </div>
    </div>
    <div v-if="editable === true" class="flex gap-4 mt-8">
      <vs-button @click="createModule()" class="w-32">{{ 'Create' }}</vs-button>
      <vs-button class="w-32 bg-grey" @click="$router.push(`/${$route.params.lang}/ops-admin/agents/training-program?tab=content&programId=${$route.query.programId}`).catch(() => {})">{{ 'Cancel' }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18nData from '../../../i18n/i18nData'
import { sendRequest } from '../../../http/axios/requestHelper'

export default {
  data () {
    return {
      module: {},
      editable: true,
      programId: this.$route.query.programId,
      chapterId: this.$route.query.chapterId
    }
  },
  watch: {
    '$route.params.lang' () {
      this.$router.push({
        query: {
          chapterId: this.chapterId,
          programId: this.programId
        }
      }).catch(() => {})
    }
  },
  methods: {
    createModule () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const moduleObj = {
            name: this.module.name,
            chapter: this.$route.query.chapterId,
            files: [
              {
                title: 'N/A',
                file_key: this.module.module_url
              }
            ]
          }
          sendRequest(true, false, this, 'api/v1/training/modules/', 'post', moduleObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Module'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.loadModule()
            }
          )
        }
      })
    },
    loadModule () {
      sendRequest(true, false, this, 'api/v1/training/modules/', 'get', null, true,
        (response) => {
          const modules = response.data.results.filter(item => item.chapter === Number(this.$route.query.chapterId))
          if (modules.length > 0) {
            this.module = modules[0]
            this.editable = false
          }
        }
      )
    }
  },
  created () {
    this.loadModule()
  }
}
</script>

<style lang="scss">
.input-order {
  border: solid 1px #CDCDCD;
  padding: 8px;
  border-radius: 4px;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: #9DADC2 !important;
  }
}
</style>